<template>
  <div style="line-height: 12px" :style="{ width }">
    <a-tooltip v-if="showToolTip" placement="topLeft">
      <template slot="title">
        <span>{{ content }}</span>
      </template>
      <div ref="contentRef" class="ant-steps-item-description-content">
        {{ content }}
      </div>
    </a-tooltip>
    <div v-else ref="contentRef" class="ant-steps-item-description-content">
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'item',
  data() {
    return {
      showToolTip: false,
    }
  },
  mounted() {},
  methods: {
    isShowToolTip() {
      const el = this.$refs.contentRef
      console.log(`el.clientWidth`, el.clientWidth)
      console.log(`el.scrollWidth`, el.scrollWidth)
      console.log(`el.clientWidth < el.scrollWidth`, el.clientWidth < el.scrollWidth)
      this.showToolTip = el.clientWidth < el.scrollWidth
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.isShowToolTip()
      }
    },
  },
  props: {
    content: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '98%',
    },
  },
}
</script>

<style lang="less" scoped></style>
